import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Module35_Map {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        if(document.querySelector('.module-35-map .hotspot')) {
            cache.hotspot = document.querySelectorAll('.module-35-map .hotspot .icon');
            cache.hotspotMobile = document.querySelectorAll('.module-35-map .hotspot_mobile .title');
            cache.popupCta = document.querySelectorAll('.module-35-map .open_pop-dash');
            cache.popupClose = document.querySelectorAll('.module-35-map_pop.popup_dash .close');
            cache.map = document.querySelector('.module-35-map .img_container');
        }
    };

    let registerEvents = () => {
        cache.map.addEventListener("click", closeContent);

        if(document.querySelector('.module-35-map .hotspot')) {
            for (let i = 0; i < cache.hotspot.length; i++) {
                cache.hotspot[i].addEventListener("click", toggleContent);
            }
        }

        if(document.querySelector('.module-35-map .hotspot_mobile')) {
            for (let i = 0; i < cache.hotspotMobile.length; i++) {
                cache.hotspotMobile[i].addEventListener("click", toggleContentMobile);
            }
        }

        if(document.querySelector('.module-35-map .open_pop-dash')) {
            for (let i = 0; i < cache.popupCta.length; i++) {
                cache.popupCta[i].addEventListener("click", openPopup);
            }
        }

        if(document.querySelector('.module-35-map_pop.popup_dash .close')) {
            for (let i = 0; i < cache.popupClose.length; i++) {
                cache.popupClose[i].addEventListener("click", closePopup);
            }
        }
        
    };

    let toggleContent = function() {
        const icon = this;
        const hs = icon.closest('.hotspot');
        const box = hs.querySelector('.content_box');

        if(icon.classList.contains('open')) {
            // CLOSE
            icon.classList.remove('open');
            hs.classList.remove('open');
            if(globalVar.mobile.matches) {
                const hs_mobile = document.querySelector('.module-35-map .'+icon.dataset.hsmobile);
                const drop = hs_mobile.querySelector('.drop');
                hs_mobile.classList.remove('open');
                anime({
                    targets: drop,
                    height: 0,
                    duration: 300,
                    easing: 'easeOutQuad',
                    complete: function() {
                        drop.style.display = "none";
                    }
                });
            } else {
                if(box.classList.contains('pos-top_top')) {
                    anime({
                        targets: box,
                        scale: [1, 0.9],
                        translateY: ['-100%','-100%'],
                        opacity: [1,0],
                        duration: 300,
                        easing: 'easeOutQuad',
                        complete: function() {
                            box.style.display = "none";
                        }
                    });
                } else {
                    anime({
                        targets: box,
                        scale: [1, 0.9],
                        opacity: [1,0],
                        duration: 300,
                        easing: 'easeOutQuad',
                        complete: function() {
                            box.style.display = "none";
                        }
                    });
                }
            }
        } else {
            // OPEN
            if(hs.closest('.module-35-map').querySelector('.icon.open')) {
                const current_icon = hs.closest('.module-35-map').querySelector('.icon.open');
                const current_hs = current_icon.closest('.hotspot');
                const current_box = current_hs.querySelector('.content_box');
                current_hs.querySelector('.icon').classList.remove('open');
                current_hs.classList.remove('open');
                if(globalVar.mobile.matches) {
                    const hs_mobile = document.querySelector('.module-35-map .'+current_icon.dataset.hsmobile);
                    hs_mobile.classList.remove('open');
                    const drop = hs_mobile.querySelector('.drop');
                    anime({
                        targets: drop,
                        height: 0,
                        duration: 300,
                        easing: 'easeOutQuad',
                        complete: function() {
                            drop.style.display = "none";
                        }
                    });

                } else {
                    if(current_box.classList.contains('pos-top_top')) {
                        anime({
                            targets: current_box,
                            scale: [1, 0.9],
                            translateY: ['-100%','-100%'],
                            opacity: [1,0],
                            duration: 300,
                            easing: 'easeOutQuad',
                            complete: function() {
                                current_box.style.display = "none";
                            }
                        });
                    } else {
                        anime({
                            targets: current_box,
                            scale: [1, 0.9],
                            opacity: [1,0],
                            duration: 300,
                            easing: 'easeOutQuad',
                            complete: function() {
                                current_box.style.display = "none";
                            }
                        });
                    }
                }
            }

            icon.classList.add('open');
            hs.classList.add('open');
            if(globalVar.mobile.matches) {
                const hs_mobile = document.querySelector('.module-35-map .'+icon.dataset.hsmobile);
                const drop = hs_mobile.querySelector('.drop');
                hs_mobile.classList.add('open');
                drop.style.display = "block";
                drop.style.height = "auto";
                const height = drop.offsetHeight;
                drop.style.height = 0;

                anime({
                    targets: drop,
                    height: [0,height],
                    duration: 300,
                    easing: 'easeOutQuad'
                });

            } else {
                box.style.display = "flex";
                if(box.classList.contains('pos-top_top')) {
                    anime({
                        targets: box,
                        scale: [0.9,1],
                        translateY: ['-100%','-100%'],
                        opacity: [0,1],
                        duration: 300,
                        easing: 'easeOutQuad'
                    });
                } else {
                    anime({
                        targets: box,
                        scale: [0.9,1],
                        opacity: [0,1],
                        duration: 300,
                        easing: 'easeOutQuad'
                    });
                }
            }
        }
    };


    let closeContent = function() {
        const current_icon = this.closest('.module-35-map').querySelector('.icon.open');
        const current_hs = current_icon.closest('.hotspot');
        const current_box = current_hs.querySelector('.content_box');
        current_hs.querySelector('.icon').classList.remove('open');
        current_hs.classList.remove('open');
        if(globalVar.mobile.matches) {
            const hs_mobile = document.querySelector('.module-35-map .'+current_icon.dataset.hsmobile);
            hs_mobile.classList.remove('open');
            const drop = hs_mobile.querySelector('.drop');
            anime({
                targets: drop,
                height: 0,
                duration: 300,
                easing: 'easeOutQuad',
                complete: function() {
                    drop.style.display = "none";
                }
            });

        } else {
            if(current_box.classList.contains('pos-top_top')) {
                anime({
                    targets: current_box,
                    scale: [1, 0.9],
                    translateY: ['-100%','-100%'],
                    opacity: [1,0],
                    duration: 300,
                    easing: 'easeOutQuad',
                    complete: function() {
                        current_box.style.display = "none";
                    }
                });
            } else {
                anime({
                    targets: current_box,
                    scale: [1, 0.9],
                    opacity: [1,0],
                    duration: 300,
                    easing: 'easeOutQuad',
                    complete: function() {
                        current_box.style.display = "none";
                    }
                });
            }
        }
    }


    let toggleContentMobile = function() {
        const icon = document.querySelector('.module-35-map .'+this.dataset.icon);

        if(icon.classList.contains('open')) {
            // CLOSE
            icon.classList.remove('open');
            icon.closest('.hotspot').classList.remove('open');
                const hs_mobile = document.querySelector('.module-35-map .'+icon.dataset.hsmobile);
                const drop = hs_mobile.querySelector('.drop');
                hs_mobile.classList.remove('open');
                anime({
                    targets: drop,
                    height: 0,
                    duration: 300,
                    easing: 'easeOutQuad',
                    complete: function() {
                        drop.style.display = "none";
                    }
                });
        } else {
            // OPEN
            if(icon.closest('.module-35-map').querySelector('.icon.open')) {
                const current_icon = icon.closest('.module-35-map').querySelector('.icon.open');
                const current_hs = current_icon.closest('.hotspot');
                current_hs.querySelector('.icon').classList.remove('open');
                current_hs.classList.remove('open');

                    const hs_mobile = document.querySelector('.module-35-map .'+current_icon.dataset.hsmobile);
                    hs_mobile.classList.remove('open');
                    const drop = hs_mobile.querySelector('.drop');
                    anime({
                        targets: drop,
                        height: 0,
                        duration: 300,
                        easing: 'easeOutQuad',
                        complete: function() {
                            drop.style.display = "none";
                        }
                    });
            }

            icon.classList.add('open');
            icon.closest('.hotspot').classList.add('open');
                const hs_mobile = document.querySelector('.module-35-map .'+icon.dataset.hsmobile);
                const drop = hs_mobile.querySelector('.drop');
                hs_mobile.classList.add('open');
                drop.style.display = "block";   
                drop.style.height = "auto";
                const height = drop.offsetHeight;
                drop.style.height = 0;

                anime({
                    targets: drop,
                    height: [0,height],
                    duration: 300,
                    easing: 'easeOutQuad'
                });
        }
    };

    let openPopup = function() {
        document.querySelector('html').classList.add('overflow');
        document.querySelector('body').classList.add('overflow');
        const pop = document.querySelector("."+this.dataset.pop);
        pop.style.display = "block";
        anime({
            targets: pop,
            opacity: [0,1],
            duration: 400,
            easing: 'easeOutQuad'
        });

        anime({
            targets: pop.querySelector('.popup_container'),
            translateX: ['30%','0%'],
            opacity: [0,1],
            delay: 200,
            duration: 400,
            easing: 'easeOutQuad'
        });
    };

    let closePopup = function() {
        document.querySelector('html').classList.remove('overflow');
        document.querySelector('body').classList.remove('overflow');
        const pop = this.closest('.popup_dash');
        anime({
            targets: pop,
            opacity: [1,0],
            duration: 400,
            delay: 200,
            easing: 'easeOutQuad',
            complete: function() {
                pop.style.display = "none";
            }
        });

        anime({
            targets: pop.querySelector('.popup_container'),
            translateX: ['0%','30%'],
            opacity: [1,0],
            duration: 400,
            easing: 'easeOutQuad'
        });
    };

    initFunc();
  };
}
export { Module35_Map }