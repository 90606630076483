// import { Home } from './pages/home'
import { Nav } from './components/nav'
import { PopUpEmergency } from './components/emergency_popup'
import { PopUpVid } from './components/popup_video'
import { Collapse } from './components/collapse'
import { CopyClipboard } from './components/copy-clipboard'
import { Scrollto } from './components/scrollto'
import { Module13_Projects } from './modules/module-13-projects'
import { Module15_TimelineTab } from './modules/module-15-timeline_tab'
import { Module16_SliderTab } from './modules/module-16-slider_tab'
import { Module19_Slider } from './modules/module-19-image_video'
import { Module29_AnnualNumbers } from './modules/module-29-annual_numbers'
import { Module30_Board } from './modules/module-30-board'
import { Module31_MenuAncre } from './modules/module-31-menu-ancre'
import { ModuleBw13_Redirection } from './modules/module-bw-cpt-13_redirection'
import { ModuleBw26_hoursTab } from './modules/module-bw-26-contact_hours'
import { ModuleStoriesImages } from './modules/module-stories-03-images'
import { Module35_Map } from './modules/module-35-map'
import { Dashboard } from './modules/template-dashboard'


class App {
  constructor() {
    const nav = new Nav;

    if(document.querySelector('.popup_emergency')) {
      const popUpEmergency = new PopUpEmergency;
    }

    // if(document.querySelector('body.home')) {
    //   const home = new Home;
    // }
    if(document.querySelector('.copy_link')) {
      const copy_clipboard= new CopyClipboard;
    }
    if(document.querySelector('.popup_video')) {
      const popup_vid = new PopUpVid;
    }
    if(document.querySelector('.collapse_wrap')) {
      const collapse = new Collapse;
    }
    if(document.querySelector('.scrollto')) {
      const scrollto = new Scrollto;
    }
    if(document.querySelector('.module-13-projects')) {
      const module13_Projects = new Module13_Projects;
    }
    if(document.querySelector('.module-15-timeline')) {
      const module15_TimelineTab = new Module15_TimelineTab;
    }
    if(document.querySelector('.module-16-slider_tab')) {
      const module16_SliderTab = new Module16_SliderTab;
    }
    if(document.querySelector('.module-19-image_video')) {
      const module19_Slider = new Module19_Slider;
    }
    if(document.querySelector('.module-29-annual-numbers')) {
      const module29_AnnualNumbers = new Module29_AnnualNumbers;
    }
    if(document.querySelector('.module-30-board')) {
      const module30_Board = new Module30_Board;
    }
    if(document.querySelector('.module-31-menu-anchors')) {
      const module31_MenuAncre = new Module31_MenuAncre;
    }
    if(document.querySelector('.module-bw-cpt-13_redirection')) {
      const moduleBw13_Redirection = new ModuleBw13_Redirection ;
    }
    if(document.querySelector('.module-bw-26-contact_hours')) {
      const moduleBw26_hoursTab = new ModuleBw26_hoursTab;
    }
    if(document.querySelector('.module-stories-03-images')) {
      const moduleStoriesImages = new ModuleStoriesImages;
    }
    if(document.querySelector('.module-35-map')) {
      const module35Map = new Module35_Map;
    }
    if(document.querySelector('.template-dashboard')) {
      const dashboard = new Dashboard;
    }
  }
}

window.onload = function() {
  let app = new App();
};
