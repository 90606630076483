import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Dashboard {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.popupCta = document.querySelectorAll('.mozaique .open_pop-dash');
        cache.popupClose = document.querySelectorAll('.moz_pop.popup_dash .close');
    };

    let registerEvents = () => {

        if(document.querySelector('.open_pop-dash')) {
            for (let i = 0; i < cache.popupCta.length; i++) {
                cache.popupCta[i].addEventListener("click", openPopup);
            }
        }

        if(document.querySelector('.popup_dash .close')) {
            for (let i = 0; i < cache.popupClose.length; i++) {
                cache.popupClose[i].addEventListener("click", closePopup);
            }
        }
        
    };

    let openPopup = function() {
        document.querySelector('html').classList.add('overflow');
        document.querySelector('body').classList.add('overflow');
        const pop = document.querySelector("."+this.dataset.pop);
        pop.style.display = "block";
        anime({
            targets: pop,
            opacity: [0,1],
            duration: 400,
            easing: 'easeOutQuad'
        });

        anime({
            targets: pop.querySelector('.popup_container'),
            translateX: ['30%','0%'],
            opacity: [0,1],
            delay: 200,
            duration: 400,
            easing: 'easeOutQuad'
        });
    };

    let closePopup = function() {
        document.querySelector('html').classList.remove('overflow');
        document.querySelector('body').classList.remove('overflow');
        const pop = this.closest('.popup_dash');
        anime({
            targets: pop,
            opacity: [1,0],
            duration: 400,
            delay: 200,
            easing: 'easeOutQuad',
            complete: function() {
                pop.style.display = "none";
            }
        });

        anime({
            targets: pop.querySelector('.popup_container'),
            translateX: ['0%','30%'],
            opacity: [1,0],
            duration: 400,
            easing: 'easeOutQuad'
        });
    };

    initFunc();
  };
}
export { Dashboard }